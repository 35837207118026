import { DateTime } from "luxon";

// format date to "yyyy-MM-dd"
export function parseNewyorkDate(date: string): DateTime {
  return DateTime.fromISO(date, { zone: "America/New_York" });
}

export function getRegularMarketTime(date: DateTime): [DateTime, DateTime] {
  const from = date.toUTC().set({ hour: 13, minute: 30, second: 0, millisecond: 0 });
  const to = date.toUTC().set({ hour: 20, minute: 0, second: 0, millisecond: 0 });

  return [from, to];
}

export function isRegularMarketTime(): boolean {
  const date = DateTime.now();
  const [from, to] = getRegularMarketTime(date);
  return date >= from && date <= to;
}

export function getDurationText(from: DateTime, to: DateTime = DateTime.now()): string {
  const diff = from.diff(to, ["seconds"]);
  return diff.toHuman({ unitDisplay: "short" });
}

export class DateTimeUtils {
  static formatDateTimeMin(date: DateTime): string {
    const timeString = DateTimeUtils.formatTimeSimple(date);
    return date.toLocal().toFormat(`yy년 L월 d일 ${timeString}`);
  }

  static formatDateTimeMinExceptYear(date: DateTime): string {
    const timeString = DateTimeUtils.formatTimeSimple(date);
    return date.toLocal().toFormat(`L월 d일 ${timeString}`);
  }

  static formatYYMM(date: DateTime): string {
    return date.toLocal().toFormat("yy년 L월 d일");
  }

  static formatTimeSimple(date: DateTime): string {
    const isAfternoon = date.toLocal().hour >= 12;
    const timeString = date.toLocal().toFormat("hh:mm");
    return isAfternoon ? `오후 ${timeString}` : `오전 ${timeString}`;
  }

  static formatDateTimeMinFromMills(date: number): string {
    return this.formatDateTimeMin(DateTime.fromMillis(date));
  }

  static formatDateTimeMinFromISO(date: string): string {
    return this.formatDateTimeMin(DateTime.fromISO(date));
  }

  static isDateInPast(date: DateTime): boolean {
    return date < DateTime.now();
  }
}
